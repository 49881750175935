<template>
<div style="margin-top: 80px;">
    <BasePageBreadCrumb :pages="pages" title="novo produto" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="card card-bg-trans">
                <div class="card-body pt-4 px-0">
                    <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                        <div class="form-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                          <label>Kits vínculados </label>
                                         <h6 class="text-truncate empreendi"><div v-for="(kit) in product.kits" :key="kit.id"><b>{{ kit.name }}</b> </div></h6>
                                    </div>
                                </div>
                                 <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Nome do produto </label>
                                        <input type="text" id="inputSuccess1" class="form-control" v-model="product.name" name="name" v-validate="'required'" :class="{'is-invalid': errors.has('name')}">
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <label>Subdivisão.</label>
                                         <b-form-select class="custom-select mr-sm-2" :options="subdivisions" v-model="product.subdivision_id" name="subdivisions" v-validate="'required'" :class="{'is-invalid': errors.has('subdivisions')}" ></b-form-select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 col-lg-2">
                                    <div class="form-group">
                                        <label>QTD.</label>
                                        <input type="text" id="inputSuccess1" class="form-control" v-model="product.quantity" name="quantity" v-validate="'required'" :class="{'is-invalid': errors.has('quantity')}" v-mask="'####'">
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>Referência </label>
                                        <textarea class="form-control" rows="6" v-model="product.reference" name="reference" v-validate="'required'" :class="{'is-invalid': errors.has('reference')}"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label>Descrição </label>
                                        <textarea class="form-control" rows="10" v-model="product.description"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-12">
                                    <label>Imagem</label>
                                    <vue-upload-multiple-image
                                        @upload-success="uploadImageSuccess"
                                        @before-remove="beforeRemove"
                                        @edit-image="editImage"
                                        :data-images="product.image"
                                        idUpload="myIdUpload"
                                        editUpload="myIdEdit"
                                        dragText="Clique ou arraste uma image aqui"
                                        browseText="Procurar imagem"
                                        :multiple="false"
                                        :showPrimary="false"
                                        :showEdit="false">
                                    </vue-upload-multiple-image>
                                </div>
                            </div>
                        </div>
                        <div class="form-actions">
                            <div class="text-right">
                                <button type="reset" class="btn btn-dark">Cancelar</button>
                                <button type="submit" class="btn btn-info ml-2">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ProductService from "@/services/resources/ProductService";
import SubdivisionService from "@/services/resources/SubdivisionService";
import VueUploadMultipleImage from "vue-upload-multiple-image";
const service = ProductService.build();
const serviceSubdivision = SubdivisionService.build();

export default {
   components:{
        VueUploadMultipleImage
   },
   data(){
       return{
           product: {
               name: null,
               quantity: null,
               reference: null,
               description: null,
               subdivision_id: null,
               image: []
           },
           subdivisions: [],
           id: null,
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'novo produto',
                    to: 'ProductNew'
                }
            ],
            menu:[
                {
                    name: 'Novo produto',
                    to: 'ProductNew'
                },
                {
                    name: 'Produtos',
                    to: 'Product'
                }
            ],
       }
   },
   methods:{
        uploadImageSuccess(formData, index, fileList) {
            this.product.image = fileList;
        },
        beforeRemove(index, done, fileList) {
            var r = confirm("Remover imagem?");
            if (r == true) {
                this.product.image.splice(index, 1);
                done();
            }
        },
        editImage(formData, index, fileList) {
            this.product.image = fileList;
        },
       save(){
        this.$validator.validateAll().then((result) => {
            if (result) {
                let product = Object.assign({}, this.product)
                product.image = product.image[0];

                if(this.id){
                    product.id = this.id;

                    service
                    .update(product)
                    .then(resp => {
                        this.$bvToast.toast('Seu produto foi atualizado com sucesso!', {
                            title: 'Produto atualizado',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                    })
                }else{
                    service
                    .create(product)
                    .then(resp => {
                        this.$bvToast.toast('Seu produto foi criado com sucesso!', {
                            title: 'Produto criado',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                    })
                }

          
            }
        });
       },
       fetchProduct(){

            if(!this.id){
                return;
            }

            let data = {
                id: this.id
            }

            service
            .read(data)
            .then(resp => {
                this.product.name = resp.name;
                this.product.subdivision_id = resp.subdivision_id;
                this.product.quantity = resp.quantity;
                this.product.reference = resp.reference;
                this.product.description = resp.description;
                this.product.image = [{ path: resp.image }];
                this.product.kits = resp.kits;
            })
            .catch(err => {
            console.log(err)
            })

       },
       fetchSubdivisions(){
            this.subdivisions = [{value: null, text: 'Selecione'}];

            serviceSubdivision
            .search()
            .then(resp => {
                resp.forEach(element => {
                    this.subdivisions.push({
                        value: element.id,
                        text: element.name
                    });
                });
            })
            .catch(err => {
            console.log(err)
            })

       },
   },
   mounted(){
       this.id = this.$route.params.id;
       this.fetchProduct();
       this.fetchSubdivisions();
   }
}
</script>