import Rest from '@/services/Rest';

/**
 * @typedef {SubdivisionService}
 */
export default class SubdivisionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/subdivision'
}
